import React, { useEffect } from "react";

import { Col, Row, Card, Form, Button } from '@themesberg/react-bootstrap';

import AuthContext from "../../context/AuthContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { createProduct } from "../../services/products";
import { getActiveCategory } from "../../services/category";
import showAlertMessage from "../../components/AlertMessage";
import Swal from 'sweetalert2'
import { ProgressContext } from "../../context/ProgressContext";

export default () => {
  const navigate = useHistory();
  const authCtx = useContext(AuthContext);
  const { setIsLoading } = React.useContext(ProgressContext);

    const [categories, setCategory] = React.useState([]);
    useEffect(()=>{
      getActiveCategory().then((res)=>{
        console.log(res.data);
        if(res.success && res.data){
          setCategory(res.data.categories)
        }
      })
    },[]);
    
    const handleSubmit = (event) => {
      event.preventDefault();
      
      setIsLoading(true);
      const data = new FormData(event.currentTarget);
      console.log(data.get('image'));
      var formData = new FormData();
      formData.append('image', data.get('image'));
      formData.append('name', data.get('name'));
      formData.append('description', data.get('description'));
      formData.append('price', data.get('price'));
      formData.append('category', data.get('category'));
      formData.append('isActive', true);

      
        createProduct(formData).then(async (res) => {
          setIsLoading(false);
          if (res.success && res.data) {                 
              navigate.push("/products");
              showAlertMessage(true, res.data.message);              
          } else {
            if(res.error.includes("Subscription")){
              const { value: accept } = await Swal.fire({
                title: "Sorry",
                text:res.error,
                icon:'warning',
                confirmButtonText: `Click here to subscribe`,
                showCancelButton:true,
                cancelButtonColor:'red'
              });
              if (accept) {
                navigate.push('/subscription');
              }
              // showAlertMessage(false,
              // <div dangerouslySetInnerHTML={{__html: res.error +","+'<a href="/subscription">click here to subscribe</a>'}} />);
            }else{
              showAlertMessage(false,res.error);
            }
          }
        }); 
    
    };

  return (
    <> 
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        
      </div>


      <Row>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        {/* <h5 className="mb-4">Product Information Form</h5> */}
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="name">
                <Form.Label>Product Name</Form.Label>
                <Form.Control required type="text" maxLength={30} name="name" placeholder="Enter Product name" />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="category">
                <Form.Label>Product Category</Form.Label>
                <Form.Select defaultValue="0" name="category">
                  {
                    categories.map((category) =>{
                      return (<option key={category._id} value={category._id}>{category.name}</option>)
                    })
                  }
                  
                </Form.Select>
              </Form.Group>
            </Col>
           
            
            <Col md={6} className="mb-3">
              <Form.Group id="image">
                <Form.Label>Product Image</Form.Label>
                <Form.Control name="image" required type="file" placeholder="Choose Image" />
              </Form.Group>
            </Col>          
             
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>Product Price in ETB</Form.Label>
                <Form.Control name="price" type="number" placeholder="Product price in ETB" />
              </Form.Group>
            </Col>  
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Product Description</Form.Label>
                <Form.Control name="description" maxLength={40} as="textarea" row={5}
                 placeholder="Say something about your product " />
              </Form.Group>
            </Col>
             
          </Row> 
          <div className="mt-3">
          <Button variant="danger"  type="link" onClick={()=>navigate.push('/products')}>Cancel</Button> &nbsp;&nbsp;
            <Button variant="primary" type="submit">Post Product</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
      </Row>
    </>
  );
};
